import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import api from '../../utilities/api.js';

const turnstileScript = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';

export default function Turnstile(props) {
  const {
    controlId,
    onChange,
  } = props;
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState(false);
  const [widgetId, setWidgetId] = useState(null);
  const invalidText = 'The challenge has not been verified';
  
  const turnstileLoaded = async () => {
    if (!widgetId) {
      const config = await api.getSiteConfig();
      const response = window.turnstile.render('.cf-turnstile', {
        sitekey: config.turnstileKey,
        callback: (token) => {
          if (typeof onChange === 'function') {
            onChange(new Event('change', { value: token }));
          }
          setPristine(false);
          handleValidation(token);
        },
      });
      setWidgetId(response);
    }
  };

  const setElementValidity = (valid) => {
    const element = document.querySelector('input[name="turnstile"]');
    element.setCustomValidity(valid ? '' : invalidText);
  };

  useEffect(() => {
    if (pristine) {
      setElementValidity(false);
      const element = document.querySelector('input[name="turnstile"]');
      element.addEventListener('invalid', () => {
        setPristine(false);
        setError(true);
      });
    }
  });

  useEffect(() => {
    if (!document.getElementById('turnstileScript')) {
      const script = document.createElement('script');
      script.src = turnstileScript;
      script.async = true;
      script.id = 'turnstileScript';
      script.onload = () => turnstileLoaded();
      document.body.appendChild(script);
    } else {
      turnstileLoaded();
    }
    return () => {
      if (widgetId) {
        window.turnstile.remove(widgetId);
        setWidgetId(null);
      }
    };
  }, [widgetId]);

  const handleValidation = async (value) => {
    const response = await api.turnstileValidate(value);
    setError(!response.success);
    setElementValidity(response.success);
    return !response.success;
  };
  
  const FeedbackElement = () => (error && !pristine) ? <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback> : null;

  return (
    <Form.Group controlId={controlId} className={pristine || error ? '' : 'was-validated'}>
      <div
        className="cf-turnstile"
        data-action="dgp"
        data-feedback-enabled="false"
        data-response-field="false"
        data-size="flexible"
      ></div>
      <Form.Control
        name="turnstile"
        type="text"
        isInvalid={(error && !pristine)}
      />
      <FeedbackElement />
    </Form.Group>
  );
}

Turnstile.propTypes = {
  controlId: propTypes.string,
  required: propTypes.bool,
  pristine: propTypes.bool,
  invalidText: propTypes.string,
  onChange: propTypes.func,
  defaultValue: propTypes.string,
};