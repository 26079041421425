import propTypes from 'prop-types';

import Turnstile from './Turnstile';

import { ordinals } from '../../utilities/strings';

export default function Submit(props) {
  const {
    captcha = true,
    cardCount = 1,
    controlId = null,
    disabled = false,
    label = null,
    onClick,
  } = props;

  return (
    <>
      { captcha &&
        <Turnstile />
      }
      <button
        className="btn btn-block btn-primary my-4"
        id={controlId}
        onClick={onClick}
        disabled={disabled}
      >
        { label ? label :
          'Send ' + (cardCount > 1 ? ordinals[cardCount] + ' cards' : 'your card') }
      </button>
    </>
  );
}

Submit.propTypes = {
  captcha: propTypes.bool,
  cardCount: propTypes.number,
  disabled: propTypes.bool,
  label: propTypes.string,
  onClick: propTypes.func,
};