import React from 'react';
import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

// TODO: Include a fake token request to make this dummy method slightly less dumb
// import { getPaymentProviderToken, submitOrder } from '../../utilities/api';
import { submitOrder } from '../../utilities/api';
import { generateOrderSubmissionData } from '../../utilities/transformations';
import Submit from '../Form/Submit';

const responseOptions = [
  'success',
  'failure',
];

class PaymentDummy extends React.Component {

  constructor(props) {
    super(props);
    [
      'onError',
      'requestPayment',
    ].forEach(prop => (this[prop] = this[prop].bind(this)));

    this.responseSelector = React.createRef();

    this.state = {
      response: null,
      checkoutMessage: '',
      buttonEnabled: true,
    };
  }

  static get propTypes() {
    return {
      goToNextStep: propTypes.func,
      updateOrderCompletionData: propTypes.func,
      giftData: propTypes.object,
      orderData: propTypes.object,
      totalValue: propTypes.number,
    };
  }

  setCustomerErrorMessage( response ) {
    const checkoutMessage = `Error: ${response.message || 'No error details available'}`;
    this.setState({ checkoutMessage });
  }

  requestPayment (event) {
    this.setState({ buttonEnabled: false });
    event.preventDefault();

    // Validate the sender details form
    let form = document.getElementById('sender-details-form');
    if (form.length && form.checkValidity() === false) {
      if (form.reportValidity) {
        form.reportValidity();
        this.setState({ buttonEnabled: true });
        return;
      }
    }
    // If we get past this point the form appears valid (client-side), so we continue

    const order = generateOrderSubmissionData(this.props.orderData, this.props.giftData);

    const transaction = {
      amount: this.props.totalValue,
      processor: 'dummy',
      response: this.responseSelector.current.value,
    };

    submitOrder(order, transaction)
      .then( (response) => {
        // TODO: Disable submit button after press? Not sure if that is necessary, but will cause an error if you accidentally press it twice in quick succession
        if (response.success) {
          this.props.updateOrderCompletionData(response);
          this.props.goToNextStep();
        } else {
          this.setCustomerErrorMessage(response);
          this.setState({ buttonEnabled: true });
        }
      });
  }

  onError (event) {
    console.error(event);
  }

  render () {
    return (
      <div className="PaymentDummy">

        <p className="h4">
          Fake payment options for internal testing
        </p>

        <label htmlFor="response">Payment response:</label>
        <br />
        <select name="response" ref={this.responseSelector}>
          {responseOptions.map( (option, index) => {
            return <option key={index} value={option}>{option}</option>;
          })}
        </select>
        <br />

        { this.state.checkoutMessage ? <p className="alert alert-warning">{this.state.checkoutMessage}</p> : null }

        <Row className="justify-content-center my-4">
          <Col xs={12} sm={8} lg={6}>
            <Submit
              cardCount={this.props.giftData.recipients.length}
              disabled={!this.state.buttonEnabled}
              onClick={this.requestPayment}
            />
          </Col>
        </Row>

      </div>
    );
  }

}

export default PaymentDummy;
