import React from 'react';
import propTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LoadingPlaceholder from './LoadingPlaceholder';
import PageError from './PageError';
import Order from './Order';

import { getUserByHash } from '../utilities/api';
import setWindowTitle from '../utilities/set-window-title';

class PageUser extends React.Component {

  constructor(props) {
    super(props);

    // Pick up and clear server-side provided context
    this.context = props.staticContext;
    if (typeof window !== 'undefined' && window.__INITIAL_CONTEXT__) {
      this.context = window.__INITIAL_CONTEXT__;
      window.__INITIAL_CONTEXT__ = false;
    }

    // Set up basic starting state
    this.state = {
      status: 'loading',
      user: null,
    };

    // If we already have context, populate the state
    if (this.context && this.context.data && this.context.data.user) {
      this.state = {
        status: 'okay',
        user: this.context.data.user,
      };
    }
    this._isMounted = false;
  }

  static get propTypes() {
    return {
      match: propTypes.object,
      staticContext: propTypes.object,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.state.user) {
      if (this.props.match.params.userHash) {
        getUserByHash(this.props.match.params.userHash)
          .then((userObj) => {
            if (typeof userObj !== 'object') {
              throw new Error('Unexpected return value for user hash lookup');
            }
            // Update the window title
            if (userObj.archived === true) {
              setWindowTitle('Archived User: Orders');
            } else {
              setWindowTitle('Orders from ' + userObj.firstName);
            }
            this._isMounted && this.setState({
              user: userObj,
              status: 'okay',
            });
            return userObj;
          })
          .catch((error) => {
            this._isMounted && this.setState({
              user: {},
              orders: {},
              status: 'error',
            });
            console.error(error);
          });
      } else {
        this.setState({
          user: {},
          orders: {},
          status: 'error',
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.status === 'loading') {
      return <LoadingPlaceholder />;
    }
    if (this.state.status === 'error') {
      return <PageError staticContext={this.context} />;
    }
    if (typeof this.state.user !== 'undefined') {
      const pageTitle = this.state.user.archived ?
        'Archived User' :
        `Recent orders for ${this.state.user.firstName} ${this.state.user.lastName}`;

      const pageSubtitle = this.state.user.archived ?
        'You haven’t used this service for more than a year, so we’ve deleted personally identifiable information for your safety' :
        `(${this.state.user.email})`;

      return (
        <div className="PageUser">
          <Container>
            <Row className="justify-content-center">
              <Col className="py-3">

                <Row className="mb-4">
                  <Col>
                    <h1>{pageTitle}</h1>
                    <p style={{ lineBreak: 'anywhere' }}>{pageSubtitle}</p>
                    <Link to="/occasions" className="btn btn-primary mb-4">Send a new gift</Link>
                    <hr />
                  </Col>
                </Row>

                {
                  this.state.user.orders.map((order, key) => {
                    return (
                      <Order order={order} key={key} />
                    );
                  })
                }

              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

}

export default PageUser;
